import React from "react";
import { useTheme } from "react-jss";
import Employee from "./components/Employee";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Price from "./components/Price";
import Product from "./components/Product";
import Slider from "./components/Slider";
import { useBasicStyles } from "./styles/basicStyle";
import { usePhilosophyStyles } from "./styles/philosophyStyle";
import { usePriceStyles } from "./styles/priceStyle";
import { AppTheme } from "./styles/theme";

const App = () => {
  const theme = useTheme<AppTheme>();
  const classes = useBasicStyles({ theme });
  const priceClasses = usePriceStyles({ theme });
  const philosophyClasses = usePhilosophyStyles({ theme });

  return (
    <div className={classes.App}>
      <Header />

      <div className={classes.ContentSection}>
        <div id="philosophy" className={classes.ContentContainer}>
          <h3 className={classes.HeadingCenter}>Philosophie</h3>
          <p className={classes.TextCenter + " " + philosophyClasses.PhilosophyText}>
            BANIN ist ein Friseur-Atelier mit Barbershop im Herzen der Kieler Altstadt. Bei uns stehen persönliche
            Beratung und individuelle Wünsche im Mittelpunkt.
            <br />
            <br />
            Wir nehmen uns Zeit, deine natürliche Schönheit herauszuarbeiten. Damit du genau das bekommst, was zu deiner
            Persönlichkeit und deiner aktuellen Lebenssituation passt. Dafür arbeiten wir mit organischen
            Schnitttechniken, sodass die neue Frisur dir lange Freude bereitet und nicht raus- sondern mitwächst.
            <br />
            <br />
            Das Angebot unseres Barbershops reicht vom klassischen Haarschnitt über Bartpflege und Nassrasur bis zur
            Gesichtspflege, Haarentfernung und Kopfmassage. Unsere bequemen Barber Chairs laden zum Zurücklehnen und
            Entspannen ein.
            <br />
            <br />
            Ob Balayage, Brautstyling oder eine perfekte Bartkontur – komm rein und fühl dich wohl. Wir freuen uns auf
            dich!
          </p>
        </div>
        <div id="ourSalon" className={classes.ContentContainer}>
          <h3 className={classes.Heading}>Unser Salon</h3>

          <Slider
            slides={[
              { imageSrc: "./assets/slider/Mutjaba_Rahemi_Foto_Sebastian-Weimar_3492-min.jpg" },
              { imageSrc: "./assets/slider/Mutjaba_Rahemi_Foto_Sebastian-Weimar_3929-min.jpg" },
              { imageSrc: "./assets/slider/Mutjaba_Rahemi_Foto_Sebastian-Weimar_3991-min.jpg" },
              { imageSrc: "./assets/slider/Mutjaba_Rahemi_Foto_Sebastian-Weimar_4030-min.jpg" },
              { imageSrc: "./assets/slider/Mutjaba_Rahemi_Foto_Sebastian-Weimar_4039-min.jpg" }
            ]}
          />
        </div>
      </div>

      <div id="aboutUs" className={classes.AccentContentSection}>
        <div className={classes.ContentContainer}>
          <h3 className={classes.Heading}>Über Uns</h3>

          <Employee
            name="Mujtaba"
            imageSrc="./assets/employee/Mujtaba-min.jpg"
            imageAlt="Mujtaba"
            imageTitle="Mujtaba"
            description='Die Kunst, mit Schere und Kamm umzugehen, hat Mujtaba sich früh angeeignet – schon mit 12 Jahren half er im Friseursalon seines Vaters aus und schnitt dort seinen Freunden die Haare. Inzwischen ist Mujtaba Friseurmeister und spezialisiert auf moderne Schnitttechniken sowie Colorationen. Ihm liegt die absolute Zufriedenheit seiner Kundschaft sehr am Herzen. Mit BANIN hat Mujtaba sich seinen Traum eines eigenen Friseurateliers verwirklicht und es seiner kleinen Schwester zuliebe nach ihr benannt.'
          />
          <Employee
            name="Irene"
            isReverse={true}
            imageSrc="./assets/employee/Irene-min.jpg"
            imageAlt="Irene"
            imageTitle="Irene"
            description="Friseurmeisterin Irene hat ihre Karriere bei „Rudi´s Hairshop” begonnen, Kiels legendärem und ehemals angesagtestem Friseur. Sie hat einen großen Erfahrungsschatz und ein ausgeprägtes Talent, zuzuhören und auf die Wünsche ihrer Kundinnen einzugehen. Ihre Schwerpunkte sind neben den Haarschnitten auch Strähnen und Dauerwellen."
          />
        </div>
      </div>

      <div id="productsPrices" className={classes.ContentSection}>
        <div className={classes.ContentContainer}>
          <h3 className={classes.HeadingCenter}>Produkte / Preise</h3>

          <Product
            name="Bei BANIN kommt nur das Beste auf deinen Kopf"
            imageAlt="Glynt"
            imageTitle="Glynt"
            url="https://www.glynt.com"
            imageSrc="./assets/product-image-min.jpg"
            description="Regionalität und ein bewusster Umgang mit den Ressourcen der Erde sind uns wichtig. Die von uns verwendeten Produkte stammen von einem Familienunternehmen aus Schleswig-Holstein. Die Rezepturen wurden in der Schweiz entwickelt und die Duftkompositionen entstehen in der Provence. GLYNT ist Wegbereiter bei der Nutzung nachwachsender Pflanzen-Rohstoffe in der Haarkosmetik. Bei den Verpackungen werden recycelte Materialien eingesetzt. Um deine Haare zuhause genauso optimal pflegen zu können, kannst du deine Lieblingsprodukte bei uns auch kaufen."
          />
        </div>

        <div className={classes.ContentContainer}>
          <div className={priceClasses.PricesContainer}>
            <Price name="Damenhaarschnitt" price={45} description="Waschen, Schneiden und Föhnen" />
            <Price name="Färbung & Tönung" price={45} description="Färbung, Tönung und Strähnen" />
            <Price name="Kosmetik" price={10} description="Augenbrauen, Wimpern und Gesichtshaarentfernung" />
            <Price name="Herrenhaarschnitt" price={25} description="Waschen, Schneiden und Stylen" />
            <Price name="Bart" price={15} description="Bart und Rasur" />
            <Price name="Herren Komplettpaket" price={50} description="Haarschnitt, Augenbrauen und Kopfmassage" />
          </div>
          <a className={priceClasses.PDFButton} href="./assets/BANIN_Preisliste.pdf" target="_blank">
            Preisliste
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default App;
