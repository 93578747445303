import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

// TODO: Add Hover for Button
export const useHeaderStyles = createUseStyles((theme: AppTheme) => ({
  Header: {
    width: "100%",
    height: "68vh",
    minHeight: "fit-content",
    background: "url(./assets/header-image-min.jpg) rgba(4, 7, 16, 0.5)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",
    position: "relative",
    paddingTop: 3 * theme.margins.l
  },
  TitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 3 * theme.margins.l
  },
  TitleImage: {
    width: 200
  },
  
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    MenuContainer: {
      width: "100%",
      padding: "0 10%"
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {}
}));
