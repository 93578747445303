import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useFooterStyles = createUseStyles((theme: AppTheme) => ({
  Footer: {
    width: "100%",
    background: theme.colors.accent,
    padding: `${theme.margins.l}px 0 ${theme.margins.l}px 0`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  FooterContentContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: `${theme.margins.s}px 0`,
    "& h3": {
      alignSelf: "flex-start"
    }
  },
  MapContentContainer: {
    display: "none",
    //display: "flex",
    flexDirection: "column",
    gap: `${theme.margins.s}px`,
  },
  MapContainer: {
    width: 420,
    height: 420,
    position: "relative",
    backgroundImage: "url('./assets/map.png')",
    backgroundSize: "cover",
    backgroundOrigin: "center",
    backgroundPosition: "center"
  },
  MapDescription: {
    width: 420,
    fontSize: 14,
  },
  Map: {
    width: 420,
    height: 420,
    zIndex: 1
  },
  MapOverlay: {
    position: "absolute",
    top: theme.margins.s,
    right: theme.margins.s,
    background: theme.colors.light,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    padding: theme.margins.xs,
    alignItems: "flex-end",
    "& span": {
      textAlign: "right"
    }
  },
  CopyrightOverlay: {
    position: "absolute",
    bottom: 3,
    left: 3
  },
  ContactContainer: {
    margin: 0,
    //margin: `0 0 0 ${theme.margins.l}px`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  LogoImage: {
    width: 100,
    margin: `${theme.margins.s}px 0`
  },
  Times: {
    margin: `${theme.margins.s}px 0`,
    display: "grid",
    gridTemplateColumns: "min-content min-content",
    rowGap: theme.margins.s / 4,
    columnGap: theme.margins.s,
    "& span": {
      whiteSpace: "nowrap"
    }
  },
  SocialContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: `0  0 ${theme.margins.s}px 0`
  },
  ContactInfo: {
    display: "flex",
    alignItems: "center",
    margin: `${theme.margins.xs}px 0`,
    fontSize: 18,
    letterSpacing: 2,
    "& a": {
      textDecoration: "none"
    }
  },
  ContactIcon: {
    width: 30,
    marginRight: theme.margins.m
  },
  InfoContainer: {},
  InfoAnchor: {
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: 2,
    position: "relative",
    "&:first-of-type": {
      marginRight: theme.margins.l
    },
    "&::after": {
      content: "''",
      position: "absolute",
      transition: "opacity 200ms ease-in-out, transform 200ms ease-in-out",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "1px",
      background: theme.colors.dark,
      opacity: 1,
      transform: "scale(0)",
      transformOrigin: "center"
    },
    "&:hover::after": {
      transform: "scale(1)"
    },
    "&:focus::after": {
      transform: "scale(1)"
    }
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    FooterContentContainer: {
      width: "80%",
      flexDirection: "column-reverse",
      alignItems: "center",
      margin: 0
    },
    MapContainer: {
      width: "100%",
      maxWidth: "none",
      marginTop: theme.margins.m
    },
    MapContentContainer: {
      width: "80%"
    },
    Map: {
      width: "100%",
      maxWidth: "none",
      paddingTop: "40%"
    },
    ContactContainer: {
      margin: 0
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {
    MapContentContainer: {
      width: "100%"
    },
    MapContainer: {
      width: "100%"
    },
    Footer: {
      overflow: "hidden"
    }
  }
}));
