import React, { FC } from "react";
import { useTheme } from "react-jss";
import { useBasicStyles } from "../styles/basicStyle";
import { useProductStyles } from "../styles/productStyle";
import { AppTheme } from "../styles/theme";

interface ProductProps {
  name: string;
  url: string;
  imageSrc: string;
  imageAlt: string;
  imageTitle: string;
  description: string;
}

const Price: FC<ProductProps> = ({ name, url, imageSrc, imageAlt, description }) => {
  const theme = useTheme<AppTheme>();
  const classes = useProductStyles({ theme });
  const basicClasses = useBasicStyles({ theme });

  const isTouchDevice = () => "ontouchstart" in window || navigator.maxTouchPoints > 0;

  const mouseClick = () => {
    window?.open(url, "_blank")?.focus();
  };

  return (
    <div className={classes.ProductContainer}>
      <div className={classes.ProductPictureContainer}>
        <img className={classes.ProductPicture} src={imageSrc} alt={imageAlt} onClick={mouseClick} />
        <span id={url} className={classes.ProductLink + (isTouchDevice() ? " touch" : "")}>
          Website
          <svg
            style={{ marginLeft: "4px" }}
            width="19px"
            height="11px"
            viewBox="0 0 19 11"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(-687.000000, -3405.000000)" fill="#040710" fillRule="nonzero">
                <g transform="translate(429.000000, 3187.000000)">
                  <g transform="translate(0.000000, 0.000000)">
                    <g transform="translate(162.000000, 204.000000)">
                      <path d="M105.809343,14.3190816 L106.245131,14.5642122 L114.245131,19.0642122 L115.019864,19.5 L114.245131,19.9357878 L106.245131,24.4357878 L105.809343,24.6809184 L105.319082,23.8093429 L105.754869,23.5642122 L112.091,20 L96,20 L96,19 L112.091,19 L105.754869,15.4357878 L105.319082,15.1906571 L105.809343,14.3190816 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>
      <div className={classes.ProductContent}>
        <h4 className={basicClasses.SubHeading}>{name}</h4>
        <p className={basicClasses.TextLeft}>{description}</p>
        <div className={classes.ProductIcons}>
          <img className={classes.ProductIconDerma} src="./assets/GLYNT_Icons_Produktkatalog_3.png" />
          <img className={classes.ProductIconVegan} src="./assets/GLYNT_Icons_Produktkatalog_4.png" />
          <img className={classes.ProductIconCrueltyFree} src="./assets/GLYNT_Icons_Produktkatalog_5.png" />
          <img className={classes.ProductIconFragrance} src="./assets/GLYNT_Icons_Produktkatalog_6.png" />
        </div>
      </div>
    </div>
  );
};

export default Price;
