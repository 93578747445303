import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const usePhilosophyStyles = createUseStyles((theme: AppTheme) => ({
  PhilosophyText: {
    padding: "0 16%",
    marginTop: theme.margins.s
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {
    PhilosophyText: {
      padding: "0 12%"
    }
  }
}));
