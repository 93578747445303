import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useMenuStyles = createUseStyles((theme: AppTheme) => ({
  MenuContainer: {
    width: "100%",
    position: "fixed",
    padding: "0 20%",
    left: 0,
    right: 0,
    top: 0,
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 3,
    transition: "background .3s ease-in-out",
    "& a": {
      maxHeight: 46
    },
    "&.solid": {
      background: "#040710CC"
    }
  },
  MenuLogo: {
    heigth: 46,
    width: 46
  },
  MenuItem: {
    textTransform: "uppercase",
    color: theme.colors.light,
    fontSize: 16,
    fontWeight: 300,
    textDecoration: "none",
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      transition: "opacity 200ms ease-in-out, transform 200ms ease-in-out",
      bottom: -1,
      left: 0,
      width: "100%",
      height: "1px",
      background: theme.colors.light,
      opacity: 1,
      transform: "scale(0)",
      transformOrigin: "center"
    },
    "&:hover::after": {
      transform: "scale(1)"
    },
    "&:focus::after": {
      transform: "scale(1)"
    }
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    MenuContainer: {
      width: "100%",
      padding: "0 5%"
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
    MenuContainer: {
      visibility: "hidden"
    }
  },
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {}
}));
