import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useEmployeeStyles = createUseStyles((theme: AppTheme) => ({
  EmployeeContainer: {
    width: "100%",
    margin: `${theme.margins.s}px 0`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&:last-of-type": {
      margin: `${theme.margins.s}px 0 0 0`
    }
  },
  ReverseEmployeeContainer: {
    composes: "$EmployeeContainer",
    flexDirection: "row-reverse",
    "& $EmployeeContent": {
      margin: `0 ${theme.margins.m}px 0 0`
    },
    "& $EmployeeContent *": {
      textAlign: "justify !important"
    },
    "& $EmployeeContent h4": {
      textAlign: "right !important",
    }
  },
  EmployeePicture: {
    width: "36%",
    maxWidth: 350,
    maxHeight: 350
  },
  EmployeeContent: {
    margin: `0 0 0 ${theme.margins.m}px`,
    width: "calc(80% - 260px)",
    "& *": {
      textAlign: "justify !important"
    }
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    EmployeeContent: {
      margin: `0 0 0 ${theme.margins.s}px`,
      width: "100%"
    },
    ReverseEmployeeContainer: {
      "& $EmployeeContent": {
        margin: `0 ${theme.margins.s}px 0 0`
      }
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
    EmployeeContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    ReverseEmployeeContainer: {
      "& $EmployeeContent": {
        margin: `${theme.margins.s}px 0 0 0`
      },
      "& $EmployeeContent *": {
        textAlign: "justify !important"
      },
      "& $EmployeeContent h4": {
        textAlign: "left !important",
      }
    },
    EmployeePicture: {
      width: "60%",
      height: "auto"
    },
    EmployeeContent: {
      margin: `${theme.margins.s}px 0 0 0`
    }
  },
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {
    EmployeePicture: {
      width: "100%",
      maxWidth: "none",
      maxHeight: "none"
    }
  }
}));
