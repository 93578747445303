import { useState } from "react";
import { useTheme } from "react-jss";
import { useMobileMenuStyles } from "../styles/mobileMenuStyle";
import { AppTheme } from "../styles/theme";

// Full-Screen Burger
const MobileMenu = () => {
  const theme = useTheme<AppTheme>();
  const classes = useMobileMenuStyles({ theme });

  const [isOpen, setIsOpen] = useState(false);

  const menuClicked = (open: boolean) => {
    setIsOpen(open);
    document.body.style.overflow = open ? "hidden" : "auto";
  };

  const anchorClicked = () => menuClicked(false);

  return (
    <>
      <nav className={classes.MobileMenuContainer + (isOpen ? " open" : "")}>
        <a>
          <img className={classes.MenuLogo} src="./assets/Banin_logo_light.png" />
        </a>

        <div onClick={() => menuClicked(!isOpen)} className={classes.BurgerIcon + (isOpen ? " open" : "")}>
          <div className={classes.FirstBurgerLine}></div>
          <div className={classes.MiddleBurgerLine}></div>
          <div className={classes.LastBurgerLine}></div>
        </div>
      </nav>
      <div className={classes.DropdownContainer}>
        <a onClick={anchorClicked} className={classes.MenuItem} href="#philosophy">
          Philosophie
        </a>
        <a onClick={anchorClicked} className={classes.MenuItem} href="#ourSalon">
          Unser Salon
        </a>
        <a onClick={anchorClicked} className={classes.MenuItem} href="#aboutUs">
          Über Uns
        </a>
        <a onClick={anchorClicked} className={classes.MenuItem} href="#productsPrices">
          Produkte / Preise
        </a>
        <a onClick={anchorClicked} className={classes.MenuItem} href="#contact">
          Kontakt
        </a>
      </div>
    </>
  );
};

export default MobileMenu;
