import { FC } from "react";
import { useTheme } from "react-jss";
import { useBasicStyles } from "../styles/basicStyle";
import { usePriceStyles } from "../styles/priceStyle";
import { AppTheme } from "../styles/theme";

interface PriceProps {
  name: string;
  price: number;
  description: string;
}

const Price: FC<PriceProps> = ({ name, price, description }) => {
  const theme = useTheme<AppTheme>();
  const classes = usePriceStyles({ theme });
  const basicClasses = useBasicStyles({ theme });

  return (
    <div className={classes.PriceContainer}>
      <h4 className={basicClasses.SubHeading + " " + classes.PriceTitle}>{name}</h4>
      <p className={basicClasses.TextCenter}>ab {price}€</p>
      <div className={classes.PriceDivider} />
      <p className={basicClasses.TextCenter}>{description}</p>
    </div>
  );
};

export default Price;
