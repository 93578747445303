import React from "react";
import { hydrate, render } from "react-dom";
import { ThemeProvider } from "react-jss";
import App from "./App";
import theme from "./styles/theme";

const root = document.getElementById("root") as HTMLElement;
if (root.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    root
  );
} else {
  render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    root
  );
}
