import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useMobileMenuStyles = createUseStyles((theme: AppTheme) => ({
  MobileMenuContainer: {
    width: "100%",
    position: "fixed",
    padding: `0 ${theme.margins.m}px`,
    left: 0,
    right: 0,
    top: 0,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 3,
    transition: "background .5s ease-in-out",
    visibility: "hidden",
    background: "#040710CC",
    "& a": {
      maxHeight: 46
    },
    "&.open + $DropdownContainer": {
      left: 0
    }
  },
  BurgerIcon: {
    minWidth: 36,
    minHeight: 30,
    position: "relative",
    cursor: "pointer",
    "&:hover $FirstBurgerLine": {
      top: "calc(12%)"
    },
    "&:hover $LastBurgerLine": {
      bottom: "calc(12%)"
    },
    "&.open $MiddleBurgerLine": {
      opacity: 0
    },
    "&.open $FirstBurgerLine": {
      top: "calc(50% - 1.5px)"
    },
    "&.open $LastBurgerLine": {
      bottom: "calc(50% - 1.5px)"
    }
  },
  BurgerLine: {
    width: "100%",
    height: 3,
    position: "absolute",
    background: theme.colors.light,
    transition: "all .2s ease-in-out",
    transformOrigin: "center"
  },
  FirstBurgerLine: {
    composes: "$BurgerLine",
    top: 0
  },
  MiddleBurgerLine: {
    composes: "$BurgerLine",
    top: 0,
    bottom: 0,
    margin: "auto 0"
  },
  LastBurgerLine: {
    composes: "$BurgerLine",
    bottom: 0
  },
  DropdownContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    width: "100vw",
    left: "100vw",
    height: "100vh",
    zIndex: 2,
    background: theme.colors.light
  },
  MenuLogo: {
    width: 46
  },
  MenuItem: {
    textTransform: "uppercase",
    color: theme.colors.dark,
    fontSize: 16,
    fontWeight: 300,
    textDecoration: "none",
    position: "relative",
    margin: `0 0 ${theme.margins.s}px 0`,
    "&:last-of-type": {
      margin: 0
    },
    "&::after": {
      content: "''",
      position: "absolute",
      transition: "opacity 200ms ease-in-out, transform 200ms ease-in-out",
      bottom: -1,
      left: 0,
      width: "100%",
      height: 1,
      background: theme.colors.dark,
      opacity: 1,
      transform: "scale(0)",
      transformOrigin: "center"
    },
    "&:hover::after": {
      transform: "scale(1)"
    },
    "&:focus::after": {
      transform: "scale(1)"
    }
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    MenuContainer: {
      width: "100%",
      padding: "0 10%"
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
    MobileMenuContainer: {
      visibility: "visible"
    },
    DropdownContainer: {
      transition: "left .5s ease-in-out"
    }
  },
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {}
}));
