import { useTheme } from "react-jss";
import { useHeaderStyles } from "../styles/headerStyle";
import { AppTheme } from "../styles/theme";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";

const Header = () => {
  const theme = useTheme<AppTheme>();
  const classes = useHeaderStyles({ theme });

  return (
    <div className={classes.Header}>
      <Menu />
      <MobileMenu />

      <div className={classes.TitleContainer}>
        <img className={classes.TitleImage} src="./assets/Banin_logo_light.png" alt="BANIN-LOGO" title="BANIN-LOGO" />
      </div>
    </div>
  );
};

export default Header;
