import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useSliderStyles = createUseStyles((theme: AppTheme) => ({
  Slider: {
    width: "100%",
    position: "relative",
    marginTop: theme.margins.s
  },
  Slide: {
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "56.25%",
    transition: "all .4s ease-in-out"
  },
  PreviewContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.margins.s
  },
  Preview: {
    maxWidth: 100,
    width: "10%",
    minWidth: 50,
    minHeight: 50,
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "10%",
    cursor: "pointer",
    margin: `0 ${theme.margins.s}px ${theme.margins.s}px 0`
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {}
}));
