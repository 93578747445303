import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useProductStyles = createUseStyles((theme: AppTheme) => ({
  ProductContainer: {
    width: "100%",
    margin: `${theme.margins.s}px 0`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&:last-of-type": {
      margin: `${theme.margins.s}px 0 0 0`
    }
  },
  ProductPictureContainer: {
    width: "100%",
    minWidth: "350px",
    minHeight: "fit-content",
    maxHeight: 400,
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover $ProductLink": {
      visibility: "visible",
      opacity: 1
    }
  },
  ProductPicture: {
    width: "100%",
    maxWidth: "400px",
    "&:hover + $ProductLink": {
      opacity: 1,
      left: "calc(50% - 55px)"
    }
  },
  ProductLink: {
    padding: theme.margins.xs,
    background: "#FFFFFFB3",
    position: "absolute",
    pointerEvents: "none",
    minWidth: "fit-content",
    opacity: 0,
    top: "calc(50% - 19px)",
    left: "-100%",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontWeight: "300",
    boxShadow: "2px 2px 4px #00000040",
    transition: "opacity.3s ease-in-out, left .5s ease-in-out",
    "&.touch": {
      opacity: 1,
      visibility: "visible"
    }
  },
  ProductContent: {
    margin: `0 0 0 ${theme.margins.m}px`,
    "& *": {
      textAlign: "justify"
    }
  },
  ProductIcons: {
    display: "flex",
    width: "100%",
    gap: `${theme.margins.m + theme.margins.xs}px`,
    marginTop: `${theme.margins.s}px`,
    alignContent: "center",
  },
  ProductIconDerma: {
    height: 70
  },
  ProductIconVegan: {
    height: 55
  },
  ProductIconCrueltyFree: {
    height: 70
  },
  ProductIconFragrance: {
    height: 55
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    ProductContent: {
      margin: `0 0 0 ${theme.margins.s}px`
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
    ProductContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    ProductIcons: {
      justifyContent: "center",
      gap: `${theme.margins.s}px`,
    },
    ProductPictureContainer: {
      width: "60%"
    },
    ProductContent: {
      margin: `${theme.margins.s}px 0 0 0`
    }
  },
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {
    ProductPictureContainer: {
      width: "100%"
    }
  }
}));
