import { FC, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { useSliderStyles } from "../styles/sliderStyle";
import { AppTheme } from "../styles/theme";

interface SliderProps {
  slides: Slide[];
}

interface Slide {
  imageSrc: string;
}

const Slider: FC<SliderProps> = ({ slides }) => {
  const theme = useTheme<AppTheme>();
  const classes = useSliderStyles({ theme });

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() =>
      setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length)
      , 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.Slider}>
      <div className={classes.Slide} style={{ backgroundImage: `url(${slides[slideIndex].imageSrc})` }} />
      <div className={classes.PreviewContainer}>
        {slides.map((slide, index) => {
          return (
            <div
              className={classes.Preview}
              key={index}
              onClick={() => setSlideIndex(index)}
              style={{ backgroundImage: `url(${slide.imageSrc})` }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
