import { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { useMenuStyles } from "../styles/menuStyle";
import { AppTheme } from "../styles/theme";

const Menu = () => {
  const theme = useTheme<AppTheme>();
  const classes = useMenuStyles({ theme });

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={classes.MenuContainer + (scrollPosition >= 120 ? " solid" : "")}>
      <a>
        <img className={classes.MenuLogo} src="./assets/Banin_logo_light.png" />
      </a>
      <a className={classes.MenuItem} href="#philosophy">
        Philosophie
      </a>
      <a className={classes.MenuItem} href="#ourSalon">
        Unser Salon
      </a>
      <a className={classes.MenuItem} href="#aboutUs">
        Über Uns
      </a>
      <a className={classes.MenuItem} href="#productsPrices">
        Produkte / Preise
      </a>
      <a className={classes.MenuItem} href="#contact">
        Kontakt
      </a>
    </nav>
  );
};

export default Menu;
