import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const usePriceStyles = createUseStyles((theme: AppTheme) => ({
  PricesContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  PriceContainer: {
    width: "28%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: `${theme.margins.s}px 0`,
    "& p": {
      width: "80%"
    }
  },
  PriceTitle: {
    textAlign: "center"
  },
  PriceDivider: {
    width: 40,
    height: 3,
    margin: `${theme.margins.xs}px 0`,
    background: theme.colors.dark
  },
  PDFButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: theme.margins.s,
    width: 200,
    height: 40,
    background: theme.colors.light,
    fontSize: 16,
    fontWeight: 300,
    textTransform: "uppercase",
    letterSpacing: "1px",
    boxShadow: "1px 1px 6px #00000040",
    cursor: "pointer",
    textDecoration: "none",
    borderRadius: 1
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
    PriceContainer: {
      width: "45%"
    }
  },
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {
    PricesContainer: {
      justifyContent: "center"
    },
    PriceContainer: {
      width: "80%"
    }
  }
}));
