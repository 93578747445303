import { useTheme } from "react-jss";
import { useBasicStyles } from "../styles/basicStyle";
import { useFooterStyles } from "../styles/footerStyle";
import { AppTheme } from "../styles/theme";

const Footer = () => {
  const theme = useTheme<AppTheme>();
  const basicClasses = useBasicStyles({ theme });
  const classes = useFooterStyles({ theme });

  return (
    <div className={classes.Footer}>
      <div className={classes.FooterContentContainer}>
        <div className={classes.MapContentContainer}>
          <div className={classes.MapContainer}>
            <div className={classes.MapOverlay}>
              <span className={basicClasses.Text}>
                Dänische Straße 9 <br /> 24103 Kiel
              </span>
              <a
                className={classes.PlanRouteButton}
                href="https://www.google.com/maps/dir//@54.32402,10.0704501,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x47b257225afcf4a9:0x10b7d4961edf01b9!2m2!1d10.1404895!2d54.3240403"
              >
                Route planen (Externer Link)
              </a>
            </div>

            <div className={classes.CopyrightOverlay}>
              &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Contributors
            </div>
          </div>

          <div className={classes.MapDescription}>
            Parkmöglichkeiten gibt es in den umliegenden Parkhäusern, wie z.B. Q-Park Altstadt und Parkhaus Jensendamm, oder auf den großen Parkplätzen am Anna-Pogwisch-Platz und in der Faulstraße.
          </div>
        </div>

        <div id="contact" className={classes.ContactContainer}>
          <img className={classes.LogoImage} src="./assets/Banin_logo_dark.png" />

          <span className={basicClasses.Text}>Dänische Straße 9, 24103 Kiel, Deutschland</span>

          <div className={classes.Times}>
            <span className={basicClasses.Text}>Mo - Fr</span>
            <span className={basicClasses.Text}>09:00 - 18:00</span>
            <span className={basicClasses.Text}>Sa</span>
            <span className={basicClasses.Text}>09:00 - 16:00</span>
            <span className={basicClasses.Text}>So</span>
            <span className={basicClasses.Text}>Geschlossen</span>
          </div>

          <div className={classes.SocialContainer}>
            <div className={classes.ContactInfo}>
              <img className={classes.ContactIcon} src="./assets/tel-icon.png" />
              <a href="#">0431 - 696 652 77</a>
            </div>
            <div className={classes.ContactInfo}>
              <img className={classes.ContactIcon} src="./assets/mail-icon.png" />
              <a href="mailto:info@friseur-banin-kiel.de">info@friseur-banin-kiel.de</a>
            </div>
            <div className={classes.ContactInfo}>
              <img className={classes.ContactIcon} src="./assets/insta-logo-dark.png" />
              <a href="https://www.instagram.com/friseurbanin">@friseurbanin</a>
            </div>
            <div className={classes.ContactInfo}>
              <img className={classes.ContactIcon} src="./assets/facebook-logo-dark.png" />
              <a href="https://www.facebook.com/friseur-banin">facebook.com/friseur-banin</a>
            </div>
          </div>

          <div className={classes.InfoContainer}>
            <a className={classes.InfoAnchor} href="./impressum.html">
              Impressum
            </a>
            <a className={classes.InfoAnchor} href="./datenschutz.html">
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
