import { FC } from "react";
import { useTheme } from "react-jss";
import { useBasicStyles } from "../styles/basicStyle";
import { useEmployeeStyles } from "../styles/employeeStyle";
import { AppTheme } from "../styles/theme";

interface EmployeeProps {
  name: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
  imageTitle: string;
  isReverse?: boolean;
}

const Employee: FC<EmployeeProps> = ({ name, description, imageSrc, imageAlt, imageTitle, isReverse = false }) => {
  const theme = useTheme<AppTheme>();
  const classes = useEmployeeStyles({ theme });
  const basicClasses = useBasicStyles({ theme });

  return (
    <div className={isReverse ? classes.ReverseEmployeeContainer : classes.EmployeeContainer}>
      <img className={classes.EmployeePicture} title={imageTitle} alt={imageAlt} src={imageSrc} />
      <div className={classes.EmployeeContent}>
        <h4 className={basicClasses.SubHeading}>{name}</h4>
        <p className={basicClasses.TextLeft}>{description}</p>
      </div>
    </div>
  );
};

export default Employee;
