import { createUseStyles } from "react-jss";
import { AppTheme } from "./theme";

export const useBasicStyles = createUseStyles((theme: AppTheme) => ({
  "@global": {
    body: {
      margin: 0,
      background: theme.colors.background,
      color: "black"
    },
    "*, *::after, *::before": {
      boxSizing: "border-box",
      color: theme.colors.dark,
      fontFamily: "Avenir Next, Helvetica Neue, Helvetica, Arial, sans-serif",
      font: {
        size: 16
      }
    }
  },
  Heading: {
    font: {
      size: 26,
      weight: 600
    },
    display: "inline-block",
    position: "relative",
    margin: `0 0 ${theme.margins.s}px 0`,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: -3,
      left: 18,
      width: 40,
      height: 3,
      background: theme.colors.dark
    }
  },
  HeadingCenter: {
    composes: "$Heading",
    alignSelf: "center"
  },
  SubHeading: {
    font: {
      size: 22,
      weight: 600
    },
    margin: `0 0 ${theme.margins.s}px 0`
  },
  Text: {
    fontSize: 16,
    margin: 0, 
    lineHeight: "1.7em"
  },
  TextLeft: {
    composes: "$Text",
    textAlign: "left"
  },
  TextRight: {
    composes: "$Text",
    textAlign: "right"
  },
  TextCenter: {
    composes: "$Text",
    textAlign: "center"
  },
  App: {},
  ContentSection: {
    width: "100%",
    margin: 0,
    padding: `${theme.margins.l}px 0`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  AccentContentSection: {
    composes: "$ContentSection",
    background: theme.colors.accent
  },
  ContentContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    margin: `${theme.margins.s}px 0`,
    "&:not($ContentContainer:last-of-type)": {
      margin: `${theme.margins.s}px 0 ${theme.margins.l}px 0`
    },
    "&:last-of-type": {
      margin: `${theme.margins.s}px 0 0 0`
    }
  },
  [`@media (max-width: ${theme.breakpoints.smallLaptop}px)`]: {
    ContentContainer: {
      width: "80%"
    }
  },
  [`@media (max-width: ${theme.breakpoints.tablet}px)`]: {},
  [`@media (max-width: ${theme.breakpoints.phone}px)`]: {}
}));
