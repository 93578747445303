export interface AppTheme {
  colors: {
    background: string;
    accent: string;
    light: string;
    dark: string;
  };
  breakpoints: {
    smallLaptop: number;
    tablet: number;
    phone: number;
  };
  margins: {
    xs: number;
    s: number;
    m: number;
    l: number;
  };
}

const theme: AppTheme = {
  colors: {
    background: "#FFFEFC",
    accent: "#F7F1E5",
    light: "#FFFEFC",
    dark: "#040710"
  },
  breakpoints: {
    smallLaptop: 1024,
    tablet: 768,
    phone: 480
  },
  margins: {
    xs: 8,
    s: 16,
    m: 32,
    l: 64
  }
};

export default theme;
